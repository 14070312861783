import { useState } from 'react';
import { Box, useMultiStyleConfig, Flex, keyframes } from '@chakra-ui/react';
import {
  Text,
  withDatasourceCheck,
  Field,
  ImageField,
  NextImage,
  LinkField,
  FileField,
  useSitecoreContext,
  GetStaticComponentProps,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { IvaluesrcExtend } from '../../Component-models/IvaluesrcExtend';
import { fadeIn } from 'assets/animations';
import { IbaseTextDouble, TextFieldsValue } from '../../Component-models/data-items/ITextDouble';
import Link from 'next/link';
// import { I_GeneralLink } from '../Component-models/base-items/IbaseLink';
import { PriceTagsProps } from '../Currency/PriceTag';
// import { EateryIcon } from 'assets/Icons/hero/EateryIcon';
import VideoComponent from '../Video/VideoComponent';
import { useGeneralStore } from 'src/store';

type HeroProps = ComponentProps &
  PriceTagsProps & {
    fields: {
      children: IbaseTextDouble[];
      headingtag: Field<string>;
      subheading: Field<string>;
      subheadingtag: Field<string>;
      mainheading: Field<string>;
      mainimage: Field<ImageField> & IvaluesrcExtend;
      sticker: Field<ImageField> & IvaluesrcExtend;
      stickerlink: LinkField;
      trumpettext: Field<string>;
      trumpetsvg: Field<ImageField> & IvaluesrcExtend;
      mobilevideo: Field<FileField> & IvaluesrcExtend;
      biggervideo: Field<FileField> & IvaluesrcExtend;
      videoURLDesktop: Field<string>;
      videoURLMobile: Field<string>;
    };
    params: {
      GridParameters: string;
    };
  };

const slideUp = keyframes`
  0% {
    transform: translateY(-200%)
  }

  20% {
    transform: translateY(0%)
  }

  87% {
    transform: translateY(0%)
  }

  100% {
    transform: translateY(-200%)
  }
`;

const slideDown = keyframes`
  0% {
    transform: translateY(200%)
  }

  20% {
    transform: translateY(0%)
  }

  87% {
    transform: translateY(0%)
  }

  100% {
    transform: translateY(200%)
  }
`;

const Hero: React.FC = (props: HeroProps): JSX.Element => {
  const styles = useMultiStyleConfig('Hero', {});

  const { isDesktop } = useGeneralStore((state) => state);
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  // make a string array of objects with uppper and lower text
  type UpperLowerText = {
    upperText: TextFieldsValue;
    lowerText: TextFieldsValue;
  };

  let headlines: UpperLowerText[] = [];

  if (props.fields.children) {
    headlines = props.fields.children.map((child) => {
      return {
        upperText: child.fields.uppertext,
        lowerText: child.fields.lowertext,
      };
    });

    if (isEditing) {
      // remove all but the first headline
      headlines = headlines.slice(0, 1);
    }
  }

  const [currentPhrase, setCurrentPhrase] = useState(0);
  const onAnimationIteration = () => {
    if (currentPhrase < headlines.length - 1) {
      setCurrentPhrase(currentPhrase + 1);
    } else {
      setCurrentPhrase(0);
    }
  };

  // for some reason the headingtag and subheadingtag are not lowercase
  // set props.fields.headingtag.value toLowerCase
  props.fields.headingtag.value = props.fields.headingtag.value?.toLowerCase();
  props.fields.subheadingtag.value = props.fields.subheadingtag.value?.toLowerCase();

  return (
    <>
      <Box className={`ahhg-hero ${props.params.GridParameters}`} __css={{ ...styles.container }}>
        <Box __css={{ ...styles.card }} className="ahhg-hero-card">
          <VideoComponent
            videoSrc={isDesktop ? props.fields.videoURLDesktop?.value : props.fields.videoURLMobile?.value}
            videoPoster={props.fields.mainimage?.value?.src ? props.fields.mainimage?.value?.src : ''}
            className="media"
            isHero={true}
          />
          <Box __css={{ ...styles.textFields }}>
            <Flex className="trumpet" animation={`${fadeIn} 1s ease-out`} __css={{}}>
              <Box className="text">
                <Text tag={props?.fields?.headingtag?.value} field={props.fields.trumpettext} />
              </Box>
              {props.fields.trumpetsvg?.value?.src && (
                <NextImage
                  field={props.fields.trumpetsvg}
                  height="20"
                  width="20"
                  className="image"
                  unoptimized={true}
                />
              )}
            </Flex>
            {headlines.length > 1 ? (
              <h1>
                <Flex
                  h={['60px', '130px']}
                  overflow="hidden"
                  justify="center"
                  __css={{ ...styles.headline }}
                  className="headline"
                >
                  <Box
                    animation={`${slideUp} 6000ms ease-in-out infinite`}
                    transform="translateY(-200%)"
                    className="upperText"
                    onAnimationIteration={onAnimationIteration}
                  >
                    <span>{headlines[currentPhrase].upperText.value}</span>
                  </Box>
                </Flex>
                <Flex h={['60px', '130px']} overflow="hidden" justify="center" __css={{ ...styles.headline }}>
                  <Box
                    animation={`${slideDown} 6000ms ease-in-out infinite`}
                    transform="translateY(200%)"
                    className="lowerText"
                  >
                    <span> {headlines[currentPhrase].lowerText.value}</span>
                  </Box>
                </Flex>
              </h1>
            ) : (
              <Flex
                h={['120px', '260px']}
                overflow="hidden"
                justify="center"
                __css={{ ...styles.headline }}
                className="headline"
                direction="column"
              >
                {headlines.length > 0 && (
                  <h1>
                    <Box className="upperText">
                      <span>{headlines[0].upperText.value}</span>
                    </Box>
                    <Box className="lowerText">
                      <span> {headlines[0].lowerText.value}</span>
                    </Box>
                  </h1>
                )}
                {/* Show hero texts in a single header tag */}
                {/* {headlines.length > 0 && (
                  <Box className="heroText-noAnimation">
                    <Text
                      tag={props.fields.headingtag.value}
                      field={{ value: `${headlines[0].upperText.value} \n ${headlines[0].lowerText.value}` }}
                    />
                  </Box>
                )} */}
              </Flex>
            )}
            <Box className="subtitle" animation={`${fadeIn} 1s ease-out`}>
              <Text field={props.fields.subheading} tag={props.fields.subheadingtag.value} />
            </Box>
          </Box>

          <Box className="sticker" cursor="pointer">
            <Link href={props.fields.stickerlink?.value?.href ?? ''} target={props.fields.stickerlink?.value?.target}>
              {props.fields.sticker?.value?.src && <img src={props.fields.sticker?.value?.src} alt={'sticker'} />}
            </Link>
          </Box>
        </Box>
      </Box>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

// This function gets called at request time on server-side.
export const getStaticProps: GetStaticComponentProps = async () => {
  return {};
};

export default withDatasourceCheck()<HeroProps>(Hero);
