import { Box, Link, useMultiStyleConfig, useBreakpointValue } from '@chakra-ui/react';
import {
  withDatasourceCheck,
  LinkField,
  ImageField,
  NextImage,
  Link as SitecoreLink,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { imageFromBreakpoints } from 'lib/helpers';

import VideoComponent from 'components/Video/VideoComponent';
import { IGridParameters } from 'src/Component-models/base-items/IGridParameters';
import { useGeneralStore } from 'src/store';

type VideoProps = ComponentProps & {
  fields: {
    fallbackImage: ImageField;
    sticker?: ImageField;
    stickerLink?: LinkField;
    videoURL: Field<string>;
  };
  params: IGridParameters;
};
// HR-39 SingleImage
const Video = (props: VideoProps): JSX.Element => {
  const { loaded } = useGeneralStore((state) => state);

  const { fallbackImage, sticker, stickerLink, videoURL } = props.fields;
  const gridParams = props.params?.GridParameters ? props.params.GridParameters : 'col-xl-12 col-md-12 col-sm-12';
  const styles = useMultiStyleConfig('Video', {});

  const sizes = { base: 351, sm: 743, md: 975, lg: 592, xl: 610 };
  const breakpointImageParams = useBreakpointValue(
    {
      base: { cw: sizes.base, ch: sizes.base, cx: 0.5, cy: 0.5 },
      sm: { cw: sizes.sm, ch: sizes.sm, cx: 0.5, cy: 0.5 },
      md: { cw: sizes.md, ch: sizes.md, cx: 0.5, cy: 0.5 },
      lg: { cw: sizes.lg, ch: sizes.lg, cx: 0.5, cy: 0.5 },
      xl: { cw: sizes.xl, ch: sizes.xl, cx: 0.5, cy: 0.5 },
    },
    {
      fallback: 'md', // Important
    }
  );

  return loaded ? (
    <Box
      __css={styles.container}
      className={`ahhg-video ${gridParams}`}
      height={breakpointImageParams?.ch}
      width={breakpointImageParams?.cw}
    >
      <VideoComponent
        videoPoster={
          fallbackImage?.value?.src && breakpointImageParams
            ? imageFromBreakpoints(breakpointImageParams, fallbackImage.value.src)
            : ''
        }
        videoSrc={videoURL?.value || ''}
      />
      {sticker?.value?.src ? (
        <Box __css={styles.sticker} className="ahhg-video-sticker">
          {stickerLink?.value?.href ? (
            <Link as={SitecoreLink} field={stickerLink} href={stickerLink.value.href} target={stickerLink.value.target}>
              <NextImage field={sticker} fill={true} unoptimized={true} />
            </Link>
          ) : (
            <NextImage field={sticker} fill={true} unoptimized={true} />
          )}
        </Box>
      ) : null}
    </Box>
  ) : (
    <></>
  );
};

export default withDatasourceCheck()<VideoProps>(Video);
