import { Box, Icon, useMultiStyleConfig } from '@chakra-ui/react';
import { withDatasourceCheck, Field, NextImage, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Link from 'next/link';
import { ComponentProps } from 'lib/component-props';
import { IDroptreeColor } from '../../../Component-models/data-items/IDroptreeColor';
import { LinkArrowRightIcon } from 'assets/Icons/ui/LinkArrowRightIcon';

export type NavigationCardItemProps = ComponentProps & {
  id: string;
  fields: {
    color: IDroptreeColor;
    title: Field<string>;
    text: Field<string>;
    link: Field<{ href: string; description: string; target: string }>;
    image: Field<{ src: string; alt: string }>;
  };
};

const NavigationCardItem = (props: NavigationCardItemProps): JSX.Element => {
  const { title, text, link, image, color } = props.fields;

  const styles = useMultiStyleConfig('NavigationCardList', { props });

  return (
    <Box className="ahhg-navigation-card-item" __css={styles.cardContainer}>
      {link && link.value.href ? (
        <Link href={link.value.href} target={link.value.target} passHref>
          <Box flexGrow={1} display="flex" cursor="pointer" __css={styles.imageAndContentContainer}>
            <Box __css={styles.imageContainer}>
              <NextImage
                field={image}
                fill={true}
                placeholder={'empty'}
                style={{ objectFit: 'cover', objectPosition: 'center' }}
                unoptimized={true}
                imageParams={{ cw: 120, ch: 150, cx: 0.5, cy: 0.5 }}
              />
            </Box>
            <Box __css={styles.contentContainer}>
              <Box __css={styles.titleAndLinkContainer}>
                <Box __css={{ ...styles.title, color: color?.fields.colorcode.value ?? styles.title.color }}>
                  <RichText tag="h3" className="sc-rich-text" field={title} />
                </Box>
                <Icon
                  as={LinkArrowRightIcon}
                  boxSize={{ base: '30px', md: '40px' }}
                  strokeWidth={1}
                  color={color?.fields.colorcode.value ?? styles.title.color}
                  __css={styles.icon}
                />
              </Box>
              <Box __css={{ ...styles.text, color: color?.fields.colorcode.value ?? styles.text.color }}>
                <RichText tag="p" className="sc-rich-text" field={text} />
              </Box>
            </Box>
          </Box>
        </Link>
      ) : (
        <div>navigation card missing data for LINK</div>
      )}
    </Box>
  );
};

export default withDatasourceCheck()<NavigationCardItemProps>(NavigationCardItem);
