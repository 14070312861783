import {
  Field,
  withDatasourceCheck,
  constants,
  GraphQLRequestClient,
  GetServerSideComponentProps,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import {
  Box,
  Text,
  Image,
  useMultiStyleConfig,
  Button,
  Link,
  useBreakpointValue,
  Flex,
  useToken,
} from '@chakra-ui/react';
import { IbaseImage } from '../../Component-models/base-items/IbaseImage';
import { DaIcon } from 'assets/Icons/flags';
import { EnIcon } from 'assets/Icons/flags';
import { useCurrencyStore, useMenuStore } from '../../store';
import React, { useEffect, useState } from 'react';
import { useI18n } from 'next-localization';
import Breadcrumb, { BreadcrumbsType } from './Breadcrumb';
import config from 'temp/config';
import { IDroptreeColor } from '../../Component-models/data-items/IDroptreeColor';
import { IsHomePage } from 'lib/SitecoreHelper';
import { HeaderQueryDocument } from './HeaderQuery.graphql';
import { HeaderQL } from '../../Component-models/HeaderQL';
import Head from 'next/head';
import { HostelMenuProps } from 'components/menu/HostelMenu';
import { HostelMenuQueryDocument } from 'components/menu/HostelMenuQuery.graphql';

type Header = ComponentProps & {
  fields: {
    menutext: Field<string>;
    logo: IbaseImage;
    CurrencyEn: Field<string>;
    CurrencyDa: Field<string>;
    backgroundcolor: IDroptreeColor;
    whiteLogo: IbaseImage;
  };
  graphQlData: {
    allpath: BreadcrumbsType;
    header: HeaderQL;
    hostelMenu: HostelMenuProps;
  };
};

export const getServerSideProps: GetServerSideComponentProps = async (_rendering, layoutData, context) => {
  if (process.env.JSS_MODE == constants.JSS_MODE.DISCONNECTED) {
    return null;
  }
  const query = `query BreadcrumbQuery($itemId: String!, $locale: String!) {
  allpath(itemId: $itemId, locale: $locale) {
    removeBreadcrumb
    breadcrumbs {
      name
      path
    }
  }
}`;
  context.res.setHeader('Cache-Control', 'public, s-maxage=600, stale-while-revalidate=650');
  const locale = context.locale;
  const graphQLClient = new GraphQLRequestClient(config.sitecoreApiHost + '/api/breadcrumb', {
    apiKey: config.sitecoreApiKey,
  });

  const itemId = layoutData?.sitecore?.route?.itemId;
  const graphQLClienttwo = new GraphQLRequestClient(config.graphQLEndpoint, {
    apiKey: config.sitecoreApiKey,
  });
  //get hostelMenu id so we can get menuColor
  const itemIdHostelMenu =
    config.jssAppName === 'nex' ? '{7314CB77-9FFF-4C08-9A0E-3F729C897E6B}' : '{1ADE3D5D-D218-4422-B871-994198CB1831}';
  const graphQLClientHostelMenu = new GraphQLRequestClient(config.graphQLEndpoint, {
    apiKey: config.sitecoreApiKey,
  });

  // use Promise.all to fetch both queries in parallel
  const [result, headerResult, hostelMenuResult] = await Promise.all([
    graphQLClient.request<BreadcrumbsType>(query, {
      itemId: itemId,
      locale: locale,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    graphQLClienttwo.request<HeaderQL>(HeaderQueryDocument as any, {
      itemId: itemId,
      language: locale,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    graphQLClientHostelMenu.request<HostelMenuProps>(HostelMenuQueryDocument as any, {
      itemId: itemIdHostelMenu,
      language: locale,
    }),
  ]);

  return {
    graphQlData: {
      allpath: result,
      header: headerResult,
      hostelMenu: { props: hostelMenuResult },
    },
    revalidate: 600,
  };
};

const Header: React.FC = (props: Header): JSX.Element => {
  const styles = useMultiStyleConfig('Header', { isHomepage: true });
  const [show, setShow] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const { setMenuIsOpen, menuIsOpen } = useMenuStore();
  const { currentCurrency } = useCurrencyStore();
  // const breadcrumbData = useComponentProps<BreadcrumbsType>(props.rendering.uid);
  const breadcrumbData = props.graphQlData;
  const i18n = useI18n();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollPos <= 0) {
        setShow(true);
        setPrevScrollPos(currentScrollPos);
        return;
      }
      setShow(prevScrollPos > currentScrollPos);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const locale = i18n.locale();
  const isWideVersion = useBreakpointValue({ base: false, md: true });
  const isHomepage = IsHomePage() || breadcrumbData.allpath.removeBreadcrumb;

  let bgColor = props.graphQlData?.header?.item?.Headerbackgroundcolor?.jsonValue?.fields?.colorcode?.value;
  const whiteLogo = props.graphQlData?.header?.item?.HeaderWhiteLogo?.value === '1' ? true : false;
  // bgColor = 'offGrey.500'; // default STE
  // bgColor = 'lightGreen.100'; // default NEX
  if (!bgColor) {
    config.jssAppName === 'nex' ? (bgColor = 'lightGreen.100') : (bgColor = 'offGrey.500');
  }
  const hostelMenuBgColor =
    props.graphQlData?.hostelMenu?.props.item?.backgroundColor?.jsonValue?.fields?.colorcode?.value ?? 'darkGrey.700';

  const headerHexBgColor = useToken('colors', bgColor);
  const hostelMenuHexBgColor = useToken('colors', hostelMenuBgColor);

  return (
    <>
      <Head>
        <meta name="theme-color" content={menuIsOpen ? hostelMenuHexBgColor : headerHexBgColor}></meta>
      </Head>
      <Box
        className={`ahhg-header ${props.params.GridParameters} ${isHomepage ? 'is-homepage' : ''}`}
        top={{ base: 0, md: !show ? '-300px' : '0' }}
        __css={{ ...styles.header }}
        bgColor={bgColor}
        id="header"
      >
        {isWideVersion && (
          <Flex minW={32} justify="flex-start">
            <Button __css={styles.burgerMenu} onClick={() => setMenuIsOpen(true)}>
              <Box
                h="0.55rem"
                width={23}
                borderBottom="1px white solid"
                borderTop="1px white solid"
                marginBottom="0.3rem"
                marginTop="0.25rem"
              />
              <Text color="white" fontSize="0.469rem" textTransform="uppercase">
                {props.fields.menutext.value}
              </Text>
            </Button>
          </Flex>
        )}
        <Link href={locale === 'da' ? '/da' : '/'} className="image" mx="auto" bgColor={bgColor}>
          {whiteLogo ? (
            <Image alt={props.fields?.whiteLogo?.value?.alt} src={props.fields?.whiteLogo?.value?.src} margin={1.5} />
          ) : (
            <Image alt={props.fields.logo.value.alt} src={props.fields.logo.value.src} margin={1.5} />
          )}
        </Link>
        {/* await country / currency component */}
        {isWideVersion && (
          <Flex minW={32} justify="flex-end">
            <Button __css={{ ...styles.countrySelector }} onClick={() => setMenuIsOpen(true, true)}>
              <Text color={whiteLogo ? 'white' : 'black'} mr={4}>
                {locale === 'en' ? `${props.fields.CurrencyEn.value}` : `${props.fields.CurrencyDa.value}`} /{' '}
                {currentCurrency.Code}
              </Text>
              {locale === 'da' ? (
                <Box __css={{ ...styles.iconContainer }}>
                  <DaIcon />
                </Box>
              ) : locale === 'en' ? (
                <Box __css={{ ...styles.iconContainer }} onClick={() => setMenuIsOpen(true, true)}>
                  <EnIcon />
                </Box>
              ) : null}
            </Button>
          </Flex>
        )}
      </Box>
      <Box
        className={`ahhg-header--spacer ${isHomepage ? 'is-homepage' : ''}`}
        __css={{ ...styles.spacer }}
        bgColor={bgColor}
      ></Box>
      <Box bgColor={bgColor} className="ahhg-header--breadcrumb" __css={styles.breadcrumbContainer}>
        <Breadcrumb
          data={props.graphQlData?.allpath}
          whiteLogo={whiteLogo}
          headerBgColor={headerHexBgColor ?? 'white.500'}
        />
      </Box>
    </>
  );
};

export default withDatasourceCheck()<Header>(Header);
