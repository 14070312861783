import { Box, Link, useMultiStyleConfig, useBreakpointValue } from '@chakra-ui/react';
import {
  withDatasourceCheck,
  LinkField,
  ImageField,
  NextImage,
  Link as SitecoreLink,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { imageFromBreakpoints } from 'lib/helpers';
import PriceTag, { PriceTagsProps } from '../Currency/PriceTag';
import VideoComponent from '../Video/VideoComponent';

type SingleImageProps = ComponentProps &
  PriceTagsProps & {
    fields: {
      image: ImageField;
      sticker?: ImageField;
      stickerLink?: LinkField;
      videoURL: Field<string>;
    };
  };
// HR-39 SingleImage
const SingleImage = (props: SingleImageProps): JSX.Element => {
  const { image, sticker, stickerLink, videoURL } = props.fields;
  const styles = useMultiStyleConfig('SingleImage', {});
  const gridParams = props.params?.GridParameters ? props.params.GridParameters : 'col-xl-12 col-md-12 col-sm-12';

  const sizes = { base: 615, sm: 743, md: 975, lg: 592, xl: 672 };
  const breakpointImageParams = useBreakpointValue(
    {
      base: { cw: sizes.base, ch: sizes.base, cx: 0.5, cy: 0.5 },
      sm: { cw: sizes.sm, ch: sizes.sm, cx: 0.5, cy: 0.5 },
      md: { cw: sizes.md, ch: sizes.md, cx: 0.5, cy: 0.5 },
      lg: { cw: sizes.lg, ch: sizes.lg, cx: 0.5, cy: 0.5 },
      xl: { cw: sizes.xl, ch: sizes.xl, cx: 0.5, cy: 0.5 },
    },
    {
      fallback: 'md', // Important
    }
  );

  return (
    <Box __css={styles.container} className={`ahhg-single-image ${gridParams}`}>
      <Box __css={{ ...styles.image }}>
        <VideoComponent
          videoPoster={
            image?.value?.src && breakpointImageParams
              ? imageFromBreakpoints(breakpointImageParams, image.value.src)
              : ''
          }
          videoSrc={videoURL?.value ? videoURL.value : ''}
        />
      </Box>

      {props.fields?.usePricetag && props.fields.usePricetag.value ? (
        <Box __css={styles.priceTag}>
          <PriceTag {...props} />
        </Box>
      ) : sticker?.value?.src ? (
        <Box __css={styles.sticker}>
          {stickerLink?.value?.href ? (
            <Link as={SitecoreLink} field={stickerLink} href={stickerLink.value.href} target={stickerLink.value.target}>
              <NextImage field={sticker} fill={true} unoptimized={true} />
            </Link>
          ) : (
            <NextImage field={sticker} fill={true} unoptimized={true} />
          )}
        </Box>
      ) : null}
      {/* {sticker?.value?.src ? (
        <Box __css={styles.sticker}>
          {stickerLink?.value?.href ? (
            <Link as={SitecoreLink} field={stickerLink} href={stickerLink.value.href} target={stickerLink.value.target}>
              <NextImage field={sticker} layout="fill" />
            </Link>
          ) : (
            <NextImage field={sticker} layout="fill" />
          )}
        </Box>
      ) : null} */}
    </Box>
  );
};

export default withDatasourceCheck()<SingleImageProps>(SingleImage);
