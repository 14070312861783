import { Box, Text, Slide, useMultiStyleConfig, Button, SystemStyleObject, chakra } from '@chakra-ui/react';
import { useI18n } from 'next-localization';
import { ArrowLeftIcon } from 'assets/Icons/ui/ArrowLeftIcon';
import { useCurrencyStore, useMenuStore } from '../../store';
import { SupportedCurrencyKeys } from '../../store/booking-store-modules';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import axios from 'axios';
import { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-var-requires
// import { IDroptreeColor } from '../Component-models/data-items/IDroptreeColor';f

interface Props {
  backgroundcolor?: string;
  textcolor?: string;
}

const LanguageAndCurrencyPicker = (props: Props): JSX.Element => {
  const { t } = useI18n();
  const { backgroundcolor } = props;

  const { setMenuIsOpen, langAndCurrPickerIsOpen } = useMenuStore();
  const { currentCurrency, setCurrentCurrency, currencyObject } = useCurrencyStore((state) => state);
  const { sitecoreContext } = useSitecoreContext();

  const styles = useMultiStyleConfig('Menu', {});

  const i18n = useI18n();
  const locale = i18n.locale();

  const handleLanguageChange = () => {
    const newLocale = locale === 'en' ? 'da' : 'en';
    const guid = sitecoreContext.itemId;
    const model = {
      ScId: guid,
      Lang: newLocale,
    };

    axios.post(`/api/language/Getsxafullpath`, model).then((res) => {
      const _fullpath = res.data;
      // router.push(router.pathname, _fullpath, { locale: newLocale });
      newLocale === 'en' ? window.location.replace(_fullpath) : window.location.replace('/' + newLocale + _fullpath);
      // setTimeout(() => {
      //   setMenuIsOpen(false, false);
      // }, 100);
    });
  };

  const [languages, setLanguages] = useState<string[]>([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.post('/api/language/ItemLanguageVersions', {
          itemId: sitecoreContext.itemId,
        });
        const languages = response.data;
        setLanguages(languages);
      } catch (error) {
        console.error('Error fetching item language versions:', error);
      }
    };

    fetchLanguages();
  }, [sitecoreContext.itemId]);

  const handleCurrencySwitch = (currencyCode: SupportedCurrencyKeys) => {
    setCurrentCurrency(currencyCode);
    setTimeout(() => {
      setMenuIsOpen(false, false);
    }, 200);
  };

  return (
    <Slide direction="left" in={langAndCurrPickerIsOpen}>
      <Box
        __css={{
          ...styles.level2Container,
          backgroundColor: backgroundcolor ?? styles.level2Container.backgroundColor,
        }}
      >
        <Box __css={styles.level2HeaderContainer}>
          <Box __css={styles.arrowBack}>
            <chakra.div sx={{ transform: 'rotate(180deg)' }}>
              <ArrowLeftIcon cursor="pointer" onClick={() => setMenuIsOpen(true, false)} />
            </chakra.div>
            <Text marginLeft="1rem">{t('language_currency_headline')}</Text>
          </Box>
        </Box>
        <Box __css={styles.scrollContainer}>
          {languages.length > 1 && (
            <Box __css={styles.languageContainer}>
              <Box>
                <Text fontSize="18px">{t('language_label')}</Text>
                <Text fontSize="12px">{locale === 'en' ? 'English' : 'Danish'}</Text>
              </Box>

              <Button className="lang-switcher" __css={styles.languageToggle} onClick={handleLanguageChange}>
                <Box
                  className="cutout"
                  __css={{ _before: { transform: locale == 'en' ? 'translate(50px)' : undefined } }}
                />
                <DanishFlag />
                <UKFlag />
              </Button>
            </Box>
          )}
          <Box __css={{ ...styles.currencyContainer, ...(languages.length <= 1 && { marginTop: '4rem' }) }}>
            {Object.values(currencyObject).map((curr) => (
              <Button key={curr.Code} __css={styles.currencyItem} onClick={() => handleCurrencySwitch(curr.Code)}>
                <Text>
                  {curr.Code} {curr.Prefix !== curr.Code && `(${curr.Prefix})`}
                </Text>
                <Box
                  __css={{
                    ...styles.currencyItemSelector,
                    backgroundColor: currentCurrency.Code === curr.Code ? '#4E5356' : 'white',
                  }}
                >
                  {currentCurrency.Code === curr.Code ? <Box __css={styles.currencyItemSelected} /> : null}
                </Box>
              </Button>
            ))}
            <Box __css={styles.currencyText}>
              <Text>{t('currency_disclaimer')}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Slide>
  );
};

export default LanguageAndCurrencyPicker;

export const DanishFlag = ({ styles }: { styles?: SystemStyleObject }): JSX.Element => {
  return (
    <Box
      __css={
        styles ?? {
          position: 'absolute',
          top: '8px',
          left: '8px',
          height: '34px',
          width: '34px',
          overflow: 'hidden',
          borderRadius: 'full',
        }
      }
    >
      <svg style={{ minHeight: '100%' }} xmlns="http://www.w3.org/2000/svg" id="flag-icons-dk" viewBox="0 0 640 480">
        <path fill="#c8102e" d="M0 0h640.1v480H0z" />
        <path fill="#fff" d="M205.7 0h68.6v480h-68.6z" />
        <path fill="#fff" d="M0 205.7h640.1v68.6H0z" />
      </svg>
    </Box>
  );
};

export const UKFlag = ({ styles }: { styles?: SystemStyleObject }): JSX.Element => {
  return (
    <Box
      __css={
        styles ?? {
          position: 'absolute',
          top: '8px',
          right: '8px',
          height: '34px',
          width: '34px',
          overflow: 'hidden',
          borderRadius: 'full',
        }
      }
    >
      <svg style={{ minHeight: '100%' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" fill="none">
        <path
          d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z"
          fill="white"
        />
        <path
          d="M3.20436 6.06323C1.98682 7.64732 1.06875 9.47311 0.53418 11.4566H8.59769L3.20436 6.06323Z"
          fill="#0052B4"
        />
        <path
          d="M30.4662 11.4566C29.9317 9.47323 29.0135 7.64744 27.796 6.06335L22.4028 11.4566H30.4662Z"
          fill="#0052B4"
        />
        <path
          d="M0.53418 19.5436C1.06881 21.527 1.98688 23.3528 3.20436 24.9368L8.59751 19.5436H0.53418Z"
          fill="#0052B4"
        />
        <path
          d="M24.9367 3.20417C23.3526 1.98664 21.5269 1.06856 19.5435 0.533936V8.59739L24.9367 3.20417Z"
          fill="#0052B4"
        />
        <path
          d="M6.06348 27.7957C7.64756 29.0133 9.47336 29.9313 11.4568 30.466V22.4026L6.06348 27.7957Z"
          fill="#0052B4"
        />
        <path
          d="M11.4567 0.533936C9.47329 1.06856 7.6475 1.98664 6.06348 3.20411L11.4567 8.59733V0.533936Z"
          fill="#0052B4"
        />
        <path
          d="M19.5439 30.466C21.5273 29.9313 23.3531 29.0133 24.9372 27.7958L19.5439 22.4026V30.466Z"
          fill="#0052B4"
        />
        <path
          d="M22.4028 19.5436L27.796 24.9369C29.0135 23.3528 29.9317 21.527 30.4662 19.5436H22.4028Z"
          fill="#0052B4"
        />
        <path
          d="M30.8688 13.4783H17.5218H17.5218V0.131205C16.8599 0.0450469 16.1852 0 15.5 0C14.8147 0 14.1401 0.0450469 13.4783 0.131205V13.4782V13.4782H0.131205C0.0450469 14.1401 0 14.8148 0 15.5C0 16.1853 0.0450469 16.8599 0.131205 17.5217H13.4782H13.4782V30.8688C14.1401 30.955 14.8147 31 15.5 31C16.1852 31 16.8599 30.955 17.5217 30.8688V17.5218V17.5218H30.8688C30.955 16.8599 31 16.1853 31 15.5C31 14.8148 30.955 14.1401 30.8688 13.4783Z"
          fill="#D80027"
        />
        <path
          d="M19.5435 19.5435L26.4601 26.4602C26.7782 26.1422 27.0817 25.8097 27.3712 25.4651L21.4496 19.5435H19.5435V19.5435Z"
          fill="#D80027"
        />
        <path
          d="M11.4567 19.5436H11.4566L4.54004 26.4602C4.85803 26.7783 5.19049 27.0817 5.53513 27.3713L11.4567 21.4495V19.5436Z"
          fill="#D80027"
        />
        <path
          d="M11.4566 11.4566V11.4565L4.54002 4.53979C4.2219 4.85779 3.91844 5.19025 3.62891 5.53488L9.55057 11.4565H11.4566V11.4566Z"
          fill="#D80027"
        />
        <path
          d="M19.5435 11.4566L26.4602 4.53983C26.1422 4.22172 25.8097 3.91826 25.4651 3.62878L19.5435 9.55045V11.4566Z"
          fill="#D80027"
        />
      </svg>
    </Box>
  );
};
