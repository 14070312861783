import React, { useEffect, useState } from 'react';
import { Placeholder, getPublicUrl, LayoutServiceData, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Scripts from 'src/Scripts';
import {
  useCurrencyStore,
  useBookingMenuStore,
  useCartStore,
  useCompanyStore,
  useMenuStore,
  useGeneralStore,
} from './store';
import { CurrencyObject } from './store/booking-store-modules';
import { useRouter } from 'next/router';
import HeadMeta from './HeadMeta';
import BodyMeta from './BodyMeta';
import { useI18n } from 'next-localization';
import { GlobalTrackingScripts } from './Component-models/GlobalTrackingQL';
import { GlobalMetaQL } from './Component-models/GlobalMetaQL';
import { CompanyLoginResponse } from 'lib/booking-helpers';
import axios from 'axios';
import { useMediaQuery } from '@chakra-ui/react';
// import HardcodedData from 'components/HardcodedData';
import { LanguageUrls } from './Component-models/LanguageUrlsModel';

// Prefix public assets with a public URL to enable compatibility with Sitecore editors.
// If you're not supporting Sitecore editors, you can remove this.
const publicUrl = getPublicUrl();

interface LayoutProps {
  layoutData: LayoutServiceData;
  currencyRates: CurrencyObject;
  trackingScripts: GlobalTrackingScripts;
  globalMeta: GlobalMetaQL;
  langUrls: LanguageUrls;
  maintenanceStatus: boolean;
}

const Layout = ({
  layoutData,
  currencyRates,
  trackingScripts,
  globalMeta,
  langUrls,
  maintenanceStatus,
}: LayoutProps): JSX.Element => {
  const { setCurrentCurrency, setCurrencyObject, currentCurrency } = useCurrencyStore((state) => state);
  const { setModalOpen, setPaymentStepOpen, modalOpen } = useBookingMenuStore((state) => state);
  const { setCartItems, setCartItemIds } = useCartStore((state) => state);
  const { setLoaded, loaded, setIsDesktop, setMaintenanceActive } = useGeneralStore((state) => state);
  const { setLoggedIn, setCompanyDetails } = useCompanyStore((state) => state);
  const { menuIsOpen } = useMenuStore();
  const router = useRouter();
  const isPageEditing = layoutData.sitecore.context.pageEditing;
  const mainClassPageEditing = isPageEditing ? 'editing-mode' : 'prod-mode';
  const { sitecoreContext } = useSitecoreContext();
  const { locale } = useI18n();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    setCurrencyObject(currencyRates);
    setMaintenanceActive(maintenanceStatus);
    if (!currentCurrency) {
      setCurrentCurrency(currencyRates.DKK.Code);
    }

    const url = new URL(document.URL);
    if (router.asPath.includes('?transactionId')) {
      const queryParams = router.asPath.slice(2).split('&');
      const transactionId = queryParams[0].split('=')[1];
      const responseCode = queryParams[1].split('=')[1];

      if (transactionId && responseCode === 'OK') {
        setModalOpen(true);
        setPaymentStepOpen(true);
      }
    }

    //used for direct login link
    if (url.searchParams.has('directLogin')) {
      const companyId = url.searchParams.get('directLogin');
      axios
        .post<CompanyLoginResponse>(`/api/ahhg/suite8/Login`, {
          ProfileId: companyId,
          hostelCode: sitecoreContext.site?.name,
          language: locale(),
        })
        .then((response) => {
          setCompanyDetails(response.data);
          setLoggedIn(true);
          setCartItems([]);
          setCartItemIds([]);
        });
    }
    const hostelRows = document.querySelectorAll('.hostel-row');
    const opacityObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('opacityFull');
          }
        });
      },
      {
        threshold: 0,
        rootMargin: '0px 0px -150px 0px',
      }
    );
    hostelRows.forEach((hostelRow) => {
      opacityObserver.observe(hostelRow);
    });

    return () => {
      opacityObserver.disconnect();
    };
  }, [
    currencyRates,
    router.asPath,
    router.isReady,
    setCurrencyObject,
    setCurrentCurrency,
    setModalOpen,
    setPaymentStepOpen,
    currentCurrency,
    setCartItems,
    setCartItemIds,
    setCompanyDetails,
    setLoggedIn,
    sitecoreContext.site,
    locale,
  ]);

  const [scrollTop, setScrollTop] = useState(0);
  const [isDesktop] = useMediaQuery('(min-width: 1024px)');

  const lockScroll = () => {
    setScrollTop(document.documentElement.scrollTop);
    document.body.classList.add('lock-scroll');
  };

  const unlockScroll = () => {
    document.body.classList.remove('lock-scroll');
    !isDesktop && window.scrollTo(0, scrollTop);
  };

  useEffect(() => {
    modalOpen || menuIsOpen ? lockScroll() : unlockScroll();
  }, [modalOpen, menuIsOpen]);

  const { route } = layoutData.sitecore;
  // const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    !loaded ? setLoaded(true) : null;
    setIsDesktop(isDesktop);
    //used for clearing cart on browser close. Zustand store persists on browser close, so use cookies instead
    if (!document.cookie.includes('SessionActive=true')) {
      setCartItems([]);
      setCartItemIds([]);
    }
    document.cookie = 'SessionActive=true;';

    // setHasMounted(true);
  }, [isDesktop, loaded]);
  // if (!hasMounted) {
  //   return <></>;
  // }

  //for HardcodedData
  // const site = sitecoreContext.site?.name as 'nex' | 'ste' | undefined;
  // const lang = sitecoreContext.language as 'da' | 'en' | undefined;

  return (
    <>
      {/* HeadMeta creates the <head> HTML and has ALL META   */}
      {route && !layoutData.sitecore.context.pageEditing && loaded && (
        <HeadMeta
          route={route}
          publicUrl={publicUrl}
          globalscript={trackingScripts.item.HeadGlobalTracking}
          globalMeta={globalMeta}
          langUrls={langUrls}
        />
      )}
      {/* https://youtrack.arp-hansen.dk/issue/ONG-3960 */}
      {/* {!layoutData.sitecore.context.pageEditing && <HardcodedData site={site} lang={lang} />} */}
      {/* <Header /> */}
      <div>
        <header>
          <div className="container">{route && <Placeholder name="jss-header" rendering={route} />}</div>
        </header>
        <Scripts />
        {/* root placeholder for the app, which we add components to using route data */}
        <main>
          {route && !layoutData.sitecore.context.pageEditing && (
            <BodyMeta route={route} globalscript={trackingScripts.item.BodyGlobalTracking} />
          )}
          <div className={`container bootstrap-wrapper ${mainClassPageEditing}`}>
            {route && <Placeholder name="jss-main" rendering={route} />}
          </div>
          <div>{route && <Placeholder name="jss-booking" rendering={route} />}</div>
        </main>
        <footer>
          <div className="container">
            {/* Footer */}
            {route && <Placeholder name="jss-footer" rendering={route} />}
            {/* if layoutData.siteore.context.pageEditing is true - NOT show BookingStickyMenu */}
            {/*{!layoutData.sitecore.context.pageEditing && <BookingStickyMenu fields={}/>}*/}
          </div>
        </footer>
      </div>
    </>
  );
};

export default Layout;
