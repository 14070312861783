import { Box, useBreakpointValue, useMultiStyleConfig } from '@chakra-ui/react';
import React, { ReactElement, useState } from 'react';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { Iimageimage } from '../../../Component-models/base-items/IbaseImage';

interface IProps {
  item: Iimageimage;
}

const NaturalHeightImage: React.FC<IProps> = ({ item }): ReactElement => {
  const [portraitHeight, setPortraitHeight] = useState(0);
  const styles = useMultiStyleConfig('ProductCardGallery', {});
  const modalImageParams = useBreakpointValue(
    {
      base: { mw: 400 },
      sm: { cw: 743, ch: 400, cx: 0.5, cy: 0.5 },
      md: { cw: 975, ch: 800, cx: 0.5, cy: 0.5 },
      lg: { cw: 1000, ch: 900, cx: 0.5, cy: 0.5 },
      xl: { cw: 1600, ch: 1000, cx: 0.5, cy: 0.5 },
    },
    {
      fallback: 'md', // Important
    }
  );
  return (
    <Box __css={{ ...styles.portraitImageContainer }} height={portraitHeight} width="100%">
      <NextImage
        className="media"
        field={item}
        fill={true}
        placeholder={'empty'}
        priority={false}
        unoptimized={true}
        style={{ objectFit: 'cover' }}
        imageParams={modalImageParams}
        onLoad={({ target }) => {
          const { naturalHeight } = target as HTMLImageElement;
          setPortraitHeight(naturalHeight);
        }}
      />
    </Box>
  );
};

export default NaturalHeightImage;
