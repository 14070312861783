import { Box, Button, useBreakpointValue, useMultiStyleConfig } from '@chakra-ui/react';
import { NextImage, RichText, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { useMemo, useState } from 'react';
import { ComponentProps } from '../../../lib/component-props';
import { CardType } from '../../../Component-models/CardTypeModel';
import ProductCardImageGallery from './ProductCardGallery';
import { useGeneralStore } from 'src/store';
import { useI18n } from 'next-localization';

type ProductCardItemProps = ComponentProps & CardType;

const ProductCardItem = (props: ProductCardItemProps) => {
  const { loaded } = useGeneralStore((state) => state);
  const { locale } = useI18n();

  const [isExpanded, setIsExpanded] = useState(false);
  const [imageGalleryIsOpen, setImageGalleryIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const { headline, text, label, gallery, amenities, GlobalCardTextColor, modalBgColor } = props.fields;

  const styles = useMultiStyleConfig('ProductCardItem', { props });

  const handleOpenGallery = (imageIndex = 0) => {
    setImageGalleryIsOpen(true);
    setImageIndex(imageIndex);
  };

  let updatedGallery = null;

  if (gallery && gallery.length > 0) {
    updatedGallery = gallery.map((image) => {
      return {
        value: {
          src: image.url,
          alt: image.fields.Alt.value,
        },
      };
    });
  } else {
    const _arrDefault = {
      value: {
        src: 'https://via.placeholder.com/300x300',
        alt: 'placeholder',
      },
    };
    updatedGallery = [_arrDefault];
  }

  const breakpointImageParams = useBreakpointValue(
    {
      base: { cw: 232, ch: 240, cx: 0.5, cy: 0.5 },
      lg: { cw: 277, ch: 300, cx: 0.5, cy: 0.5 },
    },
    {
      fallback: 'md', // Important
    }
  );

  const breakpointGalleryParams = useBreakpointValue(
    {
      base: { cw: 99, ch: 80, cx: 0.5, cy: 0.5 },
      lg: { cw: 119, ch: 100, cx: 0.5, cy: 0.5 },
    },
    {
      fallback: 'md', // Important
    }
  );

  const breakpointPreviewText = useBreakpointValue(
    {
      base: { textLength: 76, textHeight: '46px' },
      md: { textLength: 76, textHeight: '50px' },
      lg: { textLength: 81, textHeight: '51px' },
    },
    {
      fallback: '76',
    }
  );

  // //first, remove all html tags from the text
  const previewTextNoHTML = useMemo(() => {
    const noHTML = text.value.replace(/(<([^>]+)>)/gi, '');
    if (locale() === 'en') {
      return noHTML.replace(/&rsquo;/g, "'");
    }
    return noHTML
      .replace(/&rsquo;/g, "'")
      .replace(/&oslash;/g, 'ø')
      .replace(/&aring;/g, 'å')
      .replace(/&aelig;/g, 'æ');
  }, [text.value, locale]);

  return (
    <>
      <Box __css={styles.container} className="ahhg-product-card-item">
        <Box id={headline.value} __css={styles.headerAnchorOffset} />
        <Box __css={styles.galleryContainer}>
          <Button __css={styles.mainImageContainer} onClick={() => handleOpenGallery(0)}>
            <NextImage
              field={updatedGallery[0]}
              fill={true}
              placeholder={'empty'}
              unoptimized={true}
              style={{ objectFit: 'cover', objectPosition: 'center' }}
              imageParams={breakpointImageParams}
            />
            {label ? (
              <Box
                __css={{
                  ...styles.label,
                  backgroundColor: props.fields.labelBackgroundcolor?.fields?.colorcode.value,
                  color: props.fields.labelTextColor?.fields?.colorcode.value,
                }}
              >
                {label.value}
              </Box>
            ) : null}
          </Button>
          <Box __css={styles.otherImagesContainer}>
            {gallery &&
              updatedGallery.map((image, index) => {
                // skip first image, as it's the main image
                if (index === 0) return null;
                return (
                  <Button key={index} __css={styles.smallImageContainer} onClick={() => handleOpenGallery(index)}>
                    {index === 3 && <Box __css={styles.lastSmallImageCounter}>+{updatedGallery.length - 3}</Box>}
                    <NextImage
                      field={image}
                      fill={true}
                      placeholder={'empty'}
                      unoptimized={true}
                      style={{ objectFit: 'cover', objectPosition: 'center' }}
                      imageParams={breakpointGalleryParams}
                    />
                  </Button>
                );
              })}
          </Box>
        </Box>
        <Box
          __css={{ ...styles.headline, color: GlobalCardTextColor?.fields.colorcode.value ?? styles.headline.color }}
        >
          <RichText tag="h2" className="sc-rich-text" field={headline} />
        </Box>
        <Box
          __css={{
            ...styles.text,
            color: GlobalCardTextColor?.fields.colorcode.value ?? styles.text.color,
            maxHeight: isExpanded ? 'unset' : breakpointPreviewText?.textHeight,
            overflow: 'hidden',
          }}
        >
          {loaded && <RichText tag="p" className="sc-rich-text" field={text} />}
          {breakpointPreviewText && previewTextNoHTML.length > breakpointPreviewText.textLength && (
            <Button
              __css={{
                ...styles.readMore,
                position: isExpanded ? 'static' : 'absolute',
                marginLeft: 'auto',
              }}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? props.fields.ShowLess.value : props.fields.ShowMore.value}
            </Button>
          )}
        </Box>
        <Box __css={styles.amenitiesContainer}>
          {amenities.map((amenity) => (
            <Box key={amenity.id} __css={styles.amenityItem}>
              <Box
                __css={{
                  position: 'relative',
                  height: '1.25rem',
                  width: '1.25rem',
                  color: GlobalCardTextColor?.fields.colorcode.value ?? styles.amenityText.color,
                  // css mask used instead of image to permit color filling the svg icon
                  backgroundColor: GlobalCardTextColor?.fields.colorcode.value ?? styles.amenityText.color,
                  WebkitMask: `url(${amenity.fields.icon.value.src}) no-repeat center / contain`,
                  mask: `url(${amenity.fields.icon.value.src}) no-repeat center / contain`,
                }}
              ></Box>

              <Box
                __css={{
                  ...styles.amenityText,
                  color: GlobalCardTextColor?.fields.colorcode.value ?? styles.amenityText.color,
                }}
              >
                <Text
                  className="sc-rich-text"
                  field={{
                    value: amenity.fields.text.value,
                  }}
                ></Text>
              </Box>
            </Box>
          ))}
        </Box>
        <ProductCardImageGallery
          isOpen={imageGalleryIsOpen}
          onClose={() => setImageGalleryIsOpen(false)}
          imageIndex={imageIndex}
          setImageIndex={setImageIndex}
          gallery={updatedGallery}
          modalBgColor={modalBgColor?.fields?.colorcode.value}
        />
      </Box>
    </>
  );
};

export default withDatasourceCheck()<ProductCardItemProps>(ProductCardItem);
