import React, { ReactElement } from 'react';
import { Search, Briefcase } from 'tabler-icons-react';
import { Button } from '@chakra-ui/react';
import classes from './BookingStickyMenu.module.scss';
import { useI18n } from 'next-localization';
import { shortDateFormat } from 'lib/booking-box-helpers';
import { ArrowRightIcon } from 'assets/Icons/ui/ArrowRightIcon';
import { CalendarIcon } from 'assets/Icons/CalendarIcon';

interface IProps {
  openMenu: () => Promise<void>;
  openCartHandler: () => Promise<void>;
  openRoomPickerHandler: () => Promise<void>;
  cartVisible: boolean;
  datePickerOpen: boolean;
  modalOpen: boolean;
  checkInDate: Date | null;
  checkOutDate: Date | null;
}

const BookingStickyMenuDekstop: React.FC<IProps> = ({
  openMenu,
  openCartHandler,
  openRoomPickerHandler,
  cartVisible,
  datePickerOpen,
  modalOpen,
  checkInDate,
  checkOutDate,
}): ReactElement => {
  const { t } = useI18n();
  const locale = useI18n().locale();

  return (
    <div
      id="stickyMenu"
      className={`${classes.Container} ${classes.ContainerDesktop} ${
        !datePickerOpen && modalOpen && classes.ContainerHidden
      }`}
    >
      <Button
        className={`${classes.DatePickerToggler} ${datePickerOpen && classes.DatePickerTogglerOpen}`}
        variant={'bookingStarter'}
        rightIcon={
          <div className={classes.SearchIconContainer} onClick={openRoomPickerHandler}>
            <section>
              {<p>{t('booking_sticky_menu_get_prices')}</p>}
              <Search className={classes.SearchIcon} size={22} />
            </section>
          </div>
        }
      >
        <div className={classes.DatesContainerDesktop} onClick={openMenu}>
          <CalendarIcon />
          <div className={classes.ArrivalDesktop}>
            <p className={classes.DatesLabel}>{t('bookbox_arrival')}</p>
            {<p className={classes.DatesFormatted}>{checkInDate ? shortDateFormat(checkInDate, locale) : ''}</p>}
          </div>
          <ArrowRightIcon mt={'1rem'} />
          <div className={classes.DepartureDesktop}>
            <p className={classes.DatesLabel}>{t('bookbox_departure')}</p>
            {<p className={classes.DatesFormatted}>{checkOutDate ? shortDateFormat(checkOutDate, locale) : ''}</p>}
          </div>
        </div>
      </Button>
      {cartVisible && !modalOpen && (
        <a href="#cartContainer" onClick={openCartHandler}>
          <Button height="55px" className={classes.CartToggler}>
            <Briefcase size={22} color="white" />
          </Button>
        </a>
      )}
    </div>
  );
};
export default BookingStickyMenuDekstop;
