import {
  Box,
  FormControl,
  Grid,
  GridItem,
  Input,
  InputGroup,
  Button,
  InputLeftElement,
  // InputRightElement,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { TextField, useSitecoreContext, withDatasourceCheck, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Search as SearchIcon } from 'tabler-icons-react';

// import { Button } from 'components/buttons/Button';
import { ComponentProps } from 'lib/component-props';
import { useEffect, useState, useRef, FormEvent } from 'react';
import { NextApiResponse } from '../../Component-models/SearchModels';
import SearchResult from './SearchResult';
import { useRouter } from 'next/router';
// import { SearchApiResponseModel } from './SearchModels';

type SearchProps = ComponentProps & {
  fields: {
    Headline: TextField;
    NoResults: TextField;
    Buttontext: TextField;
    SearchFieldText: TextField;
    XresultsFor: TextField;
    forLabel: TextField;
  };
};

const Search = ({ ...props }: SearchProps): JSX.Element => {
  const [results, setResults] = useState<NextApiResponse>();
  const searchRef = useRef<HTMLInputElement>(null);
  const { sitecoreContext } = useSitecoreContext();

  const router = useRouter();

  const styles = useMultiStyleConfig('Search', { props });
  const buttonLabel: string = props.fields.Buttontext?.value?.toString() ?? 'Sc_Button';
  const SearchFieldText: string = props.fields.SearchFieldText?.value?.toString() ?? 'Sc_Search';

  const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!router.isReady || !searchRef.current) {
      return;
    }
    const searchRefValue = searchRef.current.value;
    const searchQuery = encodeURIComponent(searchRefValue);
    router.push({
      pathname: router.asPath.split('?')[0],
      query: { search: decodeURIComponent(searchQuery) },
    });
    return;
  };
  // response model: ResultForCurrentPage
  const handleSetSearch = async (searchArg?: string) => {
    // clear current search
    setResults(undefined);
    const lang = sitecoreContext.language;
    const site = sitecoreContext.site?.name;
    const searchRefValue = searchRef.current?.value;
    //when submitting the form, searchArg is SyntheticEvent, so we need to check if it is string (incoming from query)
    const searchQuery = typeof searchArg === 'string' ? searchArg : searchRefValue;
    if (searchQuery) {
      const response = await fetch(
        `/api/content/search?q=${encodeURIComponent(searchQuery)}&lang=${encodeURIComponent(
          lang ?? ''
        )}&site=${encodeURIComponent(site ?? '')}`
      );
      if (response.ok) {
        const data = await response.json();
        setResults(data);
      } else {
        console.error('Error:', response.statusText);
      }
    }
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    const pathQuery = router.asPath.split('?');
    const searchQuery = pathQuery[1]?.split('=');

    if (searchQuery && searchQuery[0] === 'search') {
      handleSetSearch(searchQuery[1]);
      //decode for åøæ
      searchRef.current && (searchRef.current.defaultValue = decodeURIComponent(searchQuery[1].replace(/\+/g, ' ')));
    }
  }, [router.isReady, router.asPath]);

  return (
    <Box __css={styles.container} className="ahhg-search">
      <Box textStyle="h1" __css={{ ...styles.heading }}>
        {props.fields.Headline?.value}
      </Box>
      <Grid
        alignItems="end"
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(12, 1fr)"
        gap={{ base: 2.5, md: 10 }}
      >
        <GridItem colSpan={{ base: 12, md: 7 }}>
          <form onSubmit={handleSubmit}>
            <FormControl variant="material" id="query">
              <InputGroup>
                <InputLeftElement marginLeft="0.3rem">
                  <SearchIcon size={26} strokeWidth={1} />
                </InputLeftElement>
                <Input
                  className="ahhg-search-input"
                  placeholder={SearchFieldText}
                  sx={{ ...styles.input }}
                  ref={searchRef}
                  // onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </FormControl>
          </form>
          <Box __css={{ ...styles.divider }} />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 5 }}>
          <Button
            __css={{
              ...styles.searchButton,
              height: 'fit-content',
              marginBlock: { base: '6px' },
            }}
            className={`ahhg-search-button`}
            onClick={() => handleSubmit()}
          >
            <Box __css={{ overflow: 'hidden', position: 'relative' }}>
              <Box className="text" __css={{ ...styles.text, color: 'darkGrey.700' }}>
                <Text field={{ value: buttonLabel }} />
              </Box>
              <Box className="revert-text" __css={{ ...styles.revertText, color: 'darkGrey.700' }}>
                <Text field={{ value: buttonLabel }} />
              </Box>
            </Box>
          </Button>
        </GridItem>
      </Grid>

      {!results ? (
        <></>
      ) : results?.ResultsForCurrentPage.length == 0 ? (
        <Box textStyle="p" __css={{ ...styles.resultCount }}>
          {props.fields.NoResults?.value} “{searchRef.current?.value ?? ''}”
        </Box>
      ) : (
        results?.ResultsForCurrentPage.length > 0 && (
          <Box>
            <Box textStyle="p" __css={{ ...styles.resultCount }}>
              {/* <span style={{ fontWeight: 'bold' }}>
                {props.fields.XresultsFor?.value
                  ?.toString()
                  .replace('##X##', results.ResultsForCurrentPage.length.toString())
                  .replace('##search##', searchRef.current?.value ?? '')}
              </span> */}
              <span
                style={{ fontWeight: 'bold' }}
              >{`${results.ResultsForCurrentPage.length} ${props.fields.XresultsFor?.value}`}</span>
              {` ${props.fields.forLabel?.value} "${searchRef.current?.value ?? ''}"`}
            </Box>
            <Box __css={{ ...styles.searchResults }}>
              {results.ResultsForCurrentPage.map((result, key) => (
                <SearchResult
                  key={key}
                  Title={result.Title}
                  SampleText={result.SampleText}
                  PageLink={result.PageLink}
                  ScItemId={''}
                />
              ))}
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default withDatasourceCheck()<SearchProps>(Search);
