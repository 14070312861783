import { useTheme } from '@chakra-ui/react';
import { ComponentRendering, Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import HostelContainerWrapper from './HostelContainerWrapper';

interface ComponentProps {
  rendering: ComponentRendering;
  params: {
    reversecolumn: number | undefined;
    Styles: string;
  };
}

/**
 * Uses placeholder from:
 * /sitecore/layout/Placeholder Settings/Feature/Experience Accelerator/PageStructure/hostelcontainer
 */
export const HostelContainer = (props: ComponentProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const componentNotEmpty = props.rendering.placeholders?.hostelcontainer.some((x) => x.componentName !== undefined);
  const theme = useTheme();
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  let isFluidContainer = false;
  let noSpacingTop = false;
  let noSpacingBottom = false;
  let maxDesktopWidth = false;
  let backgroundColor = 'white';
  const reverseColumn = props.params?.reversecolumn;

  if (props.params.Styles !== undefined) {
    isFluidContainer = props.params.Styles.includes('no-side-spacing'); // Sets fluid containers
    noSpacingTop = props.params.Styles.includes('no-top-spacing');
    noSpacingBottom = props.params.Styles.includes('no-bottom-spacing');

    maxDesktopWidth = props.params.Styles.includes('desktop-width-900') ? true : false; // Sets max width of container to 900px

    // Looks for color-looking strings
    const matches = props.params.Styles.match(/[a-zA-Z]+.[\d]{2,3}/);

    // If we have any matches, let's see if the first one exists in the theme
    if (matches) {
      const colorName = matches[0];
      const colorParts = matches[0].split('.');
      if (theme.colors[colorParts[0]]?.[colorParts[1]]) {
        backgroundColor = colorName;
      }
    }
  }

  return (
    // If editing in Sitecore then use div, otherwise use Flex
    isEditing && !componentNotEmpty ? (
      <Placeholder name={'hostelcontainer'} rendering={props.rendering} />
    ) : (
      <HostelContainerWrapper
        isEditing={isEditing}
        backgroundColor={backgroundColor}
        isFluidContainer={isFluidContainer}
        noSpacingTop={noSpacingTop}
        noSpacingBottom={noSpacingBottom}
        reverseColumn={reverseColumn}
        maxDesktopWidth={maxDesktopWidth}
      >
        <Placeholder name={'hostelcontainer'} rendering={props.rendering} />{' '}
      </HostelContainerWrapper>
    )
  );
};

export default HostelContainer;
