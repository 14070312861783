import { Box, useBreakpointValue, useMultiStyleConfig } from '@chakra-ui/react';
import { imageFromBreakpoints } from 'lib/helpers';
import React, { ReactElement } from 'react';

interface IProps {
  videoSrc: string;
  videoPoster: string | null;
  className?: string;
  isHero?: boolean;
}

const VideoComponent: React.FC<IProps> = ({ videoSrc, videoPoster, className, isHero }): ReactElement => {
  const styles = useMultiStyleConfig('VideoComponent', {});

  const sizes = { base: isHero ? 351 : 600, sm: 650, md: 975, lg: 592, xl: 610 };
  const breakpointImageParams = useBreakpointValue(
    {
      base: { cw: sizes.base, ch: sizes.base, cx: 0.5, cy: 0.5 },
      sm: { cw: sizes.sm, ch: sizes.sm, cx: 0.5, cy: 0.5 },
      md: { cw: sizes.md, ch: sizes.md, cx: 0.5, cy: 0.5 },
      lg: { cw: sizes.lg, ch: sizes.lg, cx: 0.5, cy: 0.5 },
      xl: { cw: sizes.xl, ch: sizes.xl, cx: 0.5, cy: 0.5 },
    },
    {
      fallback: 'md', // Important
    }
  );

  return (
    <Box __css={styles.container}>
      <video
        src={videoSrc}
        controls={false}
        autoPlay
        className={className}
        loop
        muted
        playsInline
        poster={videoPoster && breakpointImageParams ? imageFromBreakpoints(breakpointImageParams, videoPoster) : ''}
      />
    </Box>
  );
};

export default VideoComponent;
