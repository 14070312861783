/* eslint-disable @next/next/inline-script-id */
// Path: src\BodyMeta.tsx

import { RouteData, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { BodyGlobalTracking } from './Component-models/GlobalTrackingQL';
import { BodyPageonlyTracking } from './Component-models/TrackingScripts';
import Script from 'next/script';
import React from 'react';

interface RouteFields {
  [key: string]: unknown;
  BodyPageonlyTracking: BodyPageonlyTracking[];
  globalscript: BodyGlobalTracking;
}

// Component which recieves fields and publicUrl from Layout.tsx
const BodyMeta = ({ route, globalscript }: { route: RouteData; globalscript: BodyGlobalTracking }): JSX.Element => {
  const fields = route?.fields as RouteFields;

  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  // merge all scripts into one array
  const allScripts: string[] = [];
  if (globalscript && globalscript.jsonValue) {
    globalscript.jsonValue.forEach((script) => allScripts.push(script.fields.Script.value));
  }
  fields.BodyPageonlyTracking?.forEach((script) => allScripts.push(script.fields.Script.value));

  // strip <script> tags from scripts using regex
  allScripts.forEach((script, index) => {
    // if script has <script
    if (script.includes('<script')) {
      allScripts[index] = script.replace(/<script[^>]*>/g, '').replace(/<\/script>/g, '');
    }
  });

  if (isEditing) {
    return <></>;
  } else {
    return (
      <>
        {allScripts.map((script, index) => (
          <Script key={index} strategy="lazyOnload">{`
            ${script}
          `}</Script>
        ))}
      </>
    );
  }
};

export default BodyMeta;
