import { useEffect } from 'react';
import { GetServerSideProps } from 'next';
import NotFound from 'src/NotFound';
import Layout from 'src/Layout';
import { getCurrentHub } from '@sentry/browser';
import {
  RenderingType,
  SitecoreContext,
  ComponentPropsContext,
  handleEditorFastRefresh,
  EditingComponentPlaceholder,
  constants,
  GraphQLRequestClient,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
// different componentFactory method will be used based on whether page is being edited
import { componentBuilder } from 'temp/componentBuilder';
import { dataFetcher } from 'lib/data-fetcher';
import { CurrencyObject, staticExchangeRates } from '../store/booking-store-modules';
import config from 'temp/config';
import { GlobalTrackingScripts } from '../Component-models/GlobalTrackingQL';
import { GlobalTrackingQueryDocument } from 'src/GlobalTrackingQuery.graphql';
import { GlobalMetaQL } from '../Component-models/GlobalMetaQL';
import { GlobalMetaQueryDocument } from 'src/GlobalMetaQuery.graphql';
import { LanguageUrls } from '../Component-models/LanguageUrlsModel';

const SitecorePage = ({
  notFound,
  componentProps,
  layoutData,
  currencyRates,
  trackingresult,
  metaresult,
  languageUrlsprop,
  maintenanceStatus,
}: SitecorePageProps): JSX.Element => {
  useEffect(() => {
    // Since Sitecore editors do not support Fast Refresh, need to refresh editor chromes after Fast Refresh finished
    handleEditorFastRefresh();
  });
  if (notFound || !layoutData.sitecore.route) {
    // Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
    return <NotFound />;
  }

  const isEditing = layoutData.sitecore.context.pageEditing;
  const isComponentRendering = layoutData.sitecore.context.renderingType === RenderingType.Component;
  const client = getCurrentHub().getClient();
  if (client) {
    client.getOptions().enabled = !isEditing;
  }

  return (
    <ComponentPropsContext value={componentProps}>
      <SitecoreContext componentFactory={componentBuilder.getComponentFactory({ isEditing })} layoutData={layoutData}>
        {/*
          Sitecore Pages supports component rendering to avoid refreshing the entire page during component editing.
          If you are using Experience Editor only, this logic can be removed, Layout can be left.
        */}
        {isComponentRendering ? (
          <EditingComponentPlaceholder rendering={layoutData.sitecore.route} />
        ) : (
          <Layout
            layoutData={layoutData}
            currencyRates={currencyRates}
            trackingScripts={trackingresult}
            globalMeta={metaresult}
            langUrls={languageUrlsprop}
            maintenanceStatus={maintenanceStatus}
          />
        )}
      </SitecoreContext>
    </ComponentPropsContext>
  );
};

// This function gets called at request time on server-side.
export const getServerSideProps: GetServerSideProps = async (context) => {
  const props = await sitecorePagePropsFactory.create(context);
  let currencyRates: CurrencyObject;
  if (process.env.JSS_MODE == constants.JSS_MODE.DISCONNECTED) {
    currencyRates = staticExchangeRates;
  } else {
    const fetchedCurrencyRates = await dataFetcher<CurrencyObject>(
      `${process.env.SITECORE_API_HOST}/api/ahhg/currency/getexchangerates`,
      {}
    );
    currencyRates = fetchedCurrencyRates.data;
    // context.res.setHeader('Cache-Control', 'public, s-maxage=600, stale-while-revalidate=650');
  }
  // Check if we have a redirect (e.g. custom error page)
  if (props.redirect) {
    return {
      redirect: props.redirect,
    };
  }

  // Get Global Tracking Scripts and Meta
  const rootpageItemId =
    config.jssAppName === 'nex' ? '{09D000CD-8DDD-4002-BC43-CC29BC38B36A}' : '{58191E50-B4D6-4574-91C8-5A67F8E44979}';
  const graphQLClient = new GraphQLRequestClient(config.graphQLEndpoint, {
    apiKey: config.sitecoreApiKey,
  });

  // use Promise.all to fetch both queries in parallel
  const [trackingresult, metaresult] = await Promise.all([
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    graphQLClient.request<GlobalTrackingScripts>(GlobalTrackingQueryDocument as any, {
      itemId: rootpageItemId,
      language: props.locale,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    graphQLClient.request<GlobalMetaQL>(GlobalMetaQueryDocument as any, {
      itemId: rootpageItemId,
      language: props.locale,
    }),
  ]);

  // API call to retrieve language URLs
  const languageUrlResponse = await fetch(`${process.env.SITECORE_API_HOST}/api/language/AllLanguagesWithLink`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      itemId: props.layoutData?.sitecore?.route?.itemId,
    }),
  });

  // API call to retrieve maintenance
  const maintenanceResponse = await fetch(`${process.env.SITECORE_API_HOST}/api/ahhg/maintenance/status`, {
    method: 'GET',
    headers: {
      'Content-Type': 'text/html',
    },
  });

  if (!languageUrlResponse.ok) {
    console.log(props.layoutData?.sitecore?.route?.itemId);
    console.log(JSON.stringify(languageUrlResponse));
    console.error('Failed to fetch language URLs');
  }
  if (!maintenanceResponse.ok) {
    console.error('Failed to fetch maintenance status');
  }

  const languageUrls: LanguageUrls = await languageUrlResponse.json();
  //response comes back as True or False, so we need to convert it to a boolean
  const maintenanceStatus = (await maintenanceResponse.text()) === 'True';
  context.res.setHeader('Cache-Control', 'public, s-maxage=300, stale-while-revalidate=59');
  return {
    props: { ...props, currencyRates, trackingresult, metaresult, languageUrlsprop: languageUrls, maintenanceStatus },
    notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
  };
};

export default SitecorePage;
