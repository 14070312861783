import { useEffect, useRef, useState } from 'react';
import { Box, useMultiStyleConfig, useDimensions, useToken } from '@chakra-ui/react';
import { useSitecoreContext, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { IDroptreeColor } from '../../../Component-models/data-items/IDroptreeColor';
import { SliderTwoCard } from '../../../Component-models/data-items/SliderTwoCard';
import { Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { ComponentProps } from '../../../lib/component-props';
import Slider2Item from './Slider2Item';
import { hexToRgbA, rgbToHex } from 'lib/helpers';

type Slider2Props = ComponentProps & {
  fields: {
    color: IDroptreeColor;
    // backgroundColor: IDroptreeColor; HR-508 removed
    children: Array<SliderTwoCard>;
  };
};

const Slider2 = (props: Slider2Props) => {
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  // We are artificially rerendering the swiper. We have to do this because we want to
  // change the slidesPerView prop when the viewport width changes, but the swiper is not
  // re-rendering when the dimensions value changes, so we are unmounting and remounting it
  const [loaded, setLoaded] = useState(false);
  const [hostelContainerBgColor, setHostelContainerBgColor] = useState('#000000');

  const { children } = props.fields;
  const color = props.fields.color ?? ({ fields: { colorcode: { value: '#000000' } } } as IDroptreeColor);
  const hexColor = useToken('colors', color.fields.colorcode.value);
  const rgbaColor = hexToRgbA(hexColor, 0.3);

  const styles = useMultiStyleConfig('Slider2', {});

  const ref = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(ref, true); // deprecated but alternative not available yet in chakra. change to useSize in next release

  useEffect(() => {
    setLoaded(true);
    //if window is not undefined
    if (typeof window !== 'undefined' && loaded && !isEditing) {
      setTimeout(() => {
        //wait for the swiper to load the swiper-scrollbar-eventCalendar class
        const scrollBarDrag = document.getElementsByClassName('swiper-scrollbar-drag') as HTMLCollectionOf<HTMLElement>;
        const scrollBar = document.getElementsByClassName('swiper-scrollbar') as HTMLCollectionOf<HTMLElement>;
        //find which element in scrollBarr has parent with class name swiper-scrollbar-slider1
        const scrollBarDragHorizontal = Array.from(scrollBarDrag).find((el) => {
          return el.parentElement?.className.includes('swiper-scrollbar-slider2');
        });
        const scrollBarHorizontal = Array.from(scrollBar).find((el) => {
          return el.className.includes('swiper-scrollbar-slider2');
        });
        //get closest parent with class name hostel-container
        const hostelContainer = scrollBarDragHorizontal?.closest('.hostel-container');
        //get background color of hostel-container
        if (!hostelContainer) return;
        const hostelContainerBgColor = window.getComputedStyle(hostelContainer).backgroundColor;
        //remove rgb from hostelContainerBgColor and split it into array
        const hostelContainerBgColorArray = hostelContainerBgColor.replace(/[^\d,]/g, '').split(',');
        const hexHostelContainerBgColor = rgbToHex(
          hostelContainerBgColorArray[0],
          hostelContainerBgColorArray[1],
          hostelContainerBgColorArray[2]
        );
        setHostelContainerBgColor(hexHostelContainerBgColor);

        //if scrollBarHorizontal is not undefined
        if (scrollBarDragHorizontal && scrollBarHorizontal) {
          //add background color to the scrollbar
          scrollBarDragHorizontal.style.backgroundColor = hexColor ?? 'black';
          scrollBarHorizontal.style.backgroundColor = rgbaColor ?? 'rgba(0, 0, 0, 0.3)';
        }
      }, 200);
    }
  }, [loaded, hexColor, rgbaColor, isEditing]);

  const transparentColor = `rgba(255, 255, 255, 0)`;

  return isEditing ? (
    //IF in editing, dont use the reRender hook
    <Box
      ref={ref}
      className={`ahhg-slider2 ${(props.params && props.params?.GridParameters) ?? ''}`}
      __css={{
        ...styles.container,
        background: transparentColor,
      }}
    >
      <Swiper
        className="sliderContainer"
        modules={[Scrollbar]}
        grabCursor
        scrollbar={{ draggable: true, horizontalClass: 'swiper-scrollbar-slider2' }}
        longSwipesRatio={0.1}
        breakpoints={{
          0: { slidesPerView: 4 },
          1024: { slidesPerView: 3 },
          1280: { slidesPerView: 3 },
        }}
      >
        {/* if any children show number */}
        {children?.length > 0 &&
          children.map((card) => (
            <SwiperSlide key={card.fields.title.value}>
              <Slider2Item fields={{ ...card.fields, color }} params={props.params} rendering={props.rendering} />
            </SwiperSlide>
          ))}
      </Swiper>
    </Box>
  ) : (
    <Box
      ref={ref}
      className={`ahhg-slider2 ${(props.params && props.params?.GridParameters) ?? ''}`}
      __css={{
        ...styles.container,
        background: transparentColor,
      }}
    >
      {dimensions ? (
        <div
          className={children.length <= dimensions.contentBox.width / (352 + 50) ? 'hiddenControls' : 'arrowContainer'}
        >
          <Box
            __css={styles.arrowNext}
            className="swiper-button image-slider2-button-next"
            backgroundColor={color?.fields?.colorcode?.value ?? 'white'}
          >
            <svg
              width="31"
              height="21"
              viewBox="0 0 31 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke={hostelContainerBgColor}
            >
              <line x1="28" y1="10.4178" x2="6.11959e-08" y2="10.4178" stroke="inherit" strokeWidth="1.4" />
              <path d="M21 20.418C21 14.8951 25.4772 10.418 31 10.418" stroke="inherit" strokeWidth="1.4" />
              <path d="M31 10.418C25.4772 10.418 21 5.94082 21 0.417969" stroke="inherit" strokeWidth="1.4" />
            </svg>
          </Box>
          <Box
            __css={styles.arrowPrev}
            className="swiper-button image-slider2-button-prev"
            backgroundColor={color?.fields?.colorcode?.value ?? 'white'}
          >
            <svg
              width="31"
              height="21"
              viewBox="0 0 31 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke={hostelContainerBgColor}
            >
              <line x1="3" y1="10.4182" x2="31" y2="10.4182" stroke="inherit" strokeWidth="1.4" />
              <path d="M10 0.417966C10 5.94082 5.52285 10.418 0 10.418" stroke="inherit" strokeWidth="1.4" />
              <path d="M-2.38419e-06 10.418C5.52285 10.418 10 14.8951 10 20.418" stroke="inherit" strokeWidth="1.4" />
            </svg>
          </Box>
          <Swiper
            className="sliderContainer"
            modules={[Scrollbar, Navigation]}
            navigation={{
              nextEl: '.image-slider2-button-next',
              prevEl: '.image-slider2-button-prev',
              disabledClass: 'slider2-button-disabled',
            }}
            grabCursor
            scrollbar={{ draggable: true, horizontalClass: 'swiper-scrollbar-slider2' }}
            longSwipesRatio={0.1}
            breakpoints={{
              0: { slidesPerView: 'auto' },
              1024: { slidesPerView: dimensions.contentBox.width / (352 + 95) },
            }}
          >
            {/* if any children show number */}
            {children?.length > 0 &&
              children.map((card) => (
                <SwiperSlide key={card.fields.title.value}>
                  <Slider2Item fields={{ ...card.fields, color }} params={props.params} rendering={props.rendering} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      ) : null}
    </Box>
  );
};

export default withDatasourceCheck()(Slider2);
