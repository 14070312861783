import { Box, useMultiStyleConfig, useBreakpointValue } from '@chakra-ui/react';
import { Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { SliderTwoCard } from '../../../Component-models/data-items/SliderTwoCard';
import { ComponentProps } from '../../../lib/component-props';
import Link from 'next/link';
import VideoComponent from 'components/Video/VideoComponent';
import { imageFromBreakpoints } from 'lib/helpers';

type Slider2ItemProps = ComponentProps & SliderTwoCard;

const Slider2Item = (props: Slider2ItemProps): JSX.Element => {
  const { title, tag, image, link, color, videoURL } = props.fields;
  const styles = useMultiStyleConfig('Slider2Item', {});

  const breakpointImageParams = useBreakpointValue(
    {
      base: { cw: 268, ch: 322, cx: 0.5, cy: 0.5 },
      lg: { cw: 352, ch: 424, cx: 0.5, cy: 0.5 },
      xl: { cw: 424, ch: 524, cx: 0.5, cy: 0.5 },
    },
    {
      fallback: 'md', // Important
    }
  );

  // if link.value.href is empty, the link will be disabled
  if (!link.value.href) {
    link.value.href = '#';
  }

  return (
    <Box __css={styles.container}>
      <Link href={link.value.href} target={link.value.target} className="ahhg-slider2-link">
        <Box>
          <Box __css={styles.imageContainer}>
            {/* <NextImage
              field={image}
              fill={true}
              placeholder={'empty'}
              unoptimized={true}
              style={{ objectFit: 'cover', objectPosition: 'center' }}
              imageParams={breakpointImageParams}
            /> */}
            <VideoComponent
              videoPoster={
                image?.value?.src && breakpointImageParams
                  ? imageFromBreakpoints(breakpointImageParams, image.value.src)
                  : ''
              }
              videoSrc={videoURL?.value ? videoURL.value : ''}
            />
          </Box>
          <Box __css={styles.textContainer}>
            <Box textStyle="p" __css={{ ...styles.tag, color: color?.fields.colorcode.value ?? styles.tag.color }}>
              <Text tag="p" field={tag} />
            </Box>
            <Box textStyle="h2" __css={{ ...styles.title, color: color?.fields.colorcode.value ?? styles.title.color }}>
              <Text tag="h3" field={title} />
            </Box>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

export default withDatasourceCheck()(Slider2Item);
