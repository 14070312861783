// HR-109
import { Box, useDimensions, useMultiStyleConfig, useToken, Link } from '@chakra-ui/react';
import { Field, LinkField, Text, useSitecoreContext, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Plus } from 'tabler-icons-react';
import { Scrollbar, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { IDroptreeColor } from '../../../Component-models/data-items/IDroptreeColor';
import { CardType } from '../../../Component-models/data-items/SliderOneCard';
import { ComponentProps } from '../../../lib/component-props';
import Slider1Item from './Slider1Item';
import { useEffect, useRef, useState } from 'react';
import { hexToRgbA, rgbToHex } from 'lib/helpers';
import useOnScreen from 'components/Util-Components/useOnScreen';

type Slider1Props = ComponentProps & {
  fields: {
    category: Field<string>;
    color: IDroptreeColor;
    // INTERFACE FIELDS - reused across components
    showMoreLink: LinkField;
    slider1Items: CardType[];
  };
};

const Slider1 = (props: Slider1Props) => {
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  // We are artificially rerendering the swiper. We have to do this because we want to
  // change the slidesPerView prop when the viewport width changes, but the swiper is not
  // re-rendering when the dimensions value changes, so we are unmounting and remounting it
  const [loaded, setLoaded] = useState(false);
  const [hostelContainerBgColor, setHostelContainerBgColor] = useState('#000000');

  const { category, color, showMoreLink, slider1Items } = props.fields;

  const styles = useMultiStyleConfig('Slider1', {});

  const containerRef = useRef<HTMLDivElement>(null);
  const sliderScrolledIntoView = useOnScreen(containerRef);
  const [hasScrolledInto, setHasScrolledInto] = useState(false);

  const dimensions = useDimensions(containerRef, true); // deprecated but alternative not available yet in chakra. change to useSize in next release
  const transparentColor = `rgba(255, 255, 255, 0.3)`;

  const iconContainerBackgroundMatch =
    props.params.Styles && props.params.Styles.match(/iconContainer.[a-zA-Z]+.[\d]{2,3}/);
  const iconColorMatch = props.params.Styles && props.params.Styles.match(/icon.[a-zA-Z]+.[\d]{2,3}/);

  const iconContainerBackgroundColor =
    iconContainerBackgroundMatch && iconContainerBackgroundMatch[0]
      ? iconContainerBackgroundMatch[0].replace('iconContainer.', '')
      : 'white.500';
  const iconColor = iconColorMatch && iconColorMatch[0] ? iconColorMatch[0].replace('icon.', '') : 'black.500';
  const hexIconColor = useToken('colors', iconColor);
  const rgbaIconColor = hexToRgbA(hexIconColor, 0.3);

  useEffect(() => {
    if (hasScrolledInto) return;
    setLoaded(true);
    //if window is not undefined
    if (typeof window !== 'undefined' && loaded) {
      //wait for the swiper to load the swiper-scrollbar-slider1 class
      setTimeout(() => {
        const scrollBarDrag = document.getElementsByClassName('swiper-scrollbar-drag') as HTMLCollectionOf<HTMLElement>;
        const scrollBar = document.getElementsByClassName('swiper-scrollbar') as HTMLCollectionOf<HTMLElement>;
        //find which element in scrollBarr has parent with class name swiper-scrollbar-slider1
        const scrollBarDragHorizontal = Array.from(scrollBarDrag).find((el) => {
          return el.parentElement?.className.includes('swiper-scrollbar-slider1');
        });
        const scrollBarHorizontal = Array.from(scrollBar).find((el) => {
          return el.className.includes('swiper-scrollbar-slider1');
        });
        //get closest parent with class name hostel-container
        const hostelContainer = scrollBarDragHorizontal?.closest('.hostel-container');
        //get background color of hostel-container
        if (!hostelContainer) return;
        const hostelContainerBgColor = window.getComputedStyle(hostelContainer).backgroundColor;
        //remove rgb from hostelContainerBgColor and split it into array
        const hostelContainerBgColorArray = hostelContainerBgColor.replace(/[^\d,]/g, '').split(',');
        const hexHostelContainerBgColor = rgbToHex(
          hostelContainerBgColorArray[0],
          hostelContainerBgColorArray[1],
          hostelContainerBgColorArray[2]
        );
        setHostelContainerBgColor(hexHostelContainerBgColor);

        //if scrollBarHorizontal is not undefined
        if (scrollBarDragHorizontal && scrollBarHorizontal) {
          //add background color to the scrollbar
          scrollBarDragHorizontal.style.backgroundColor = hexIconColor ?? 'black';
          scrollBarHorizontal.style.backgroundColor = rgbaIconColor ?? 'rgba(0, 0, 0, 0.3)';
        }
      }, 200);
      setHasScrolledInto(sliderScrolledIntoView);
    }
  }, [loaded, hexIconColor, rgbaIconColor, hasScrolledInto, sliderScrolledIntoView]);

  //IF in editing, dont use the reRender hook
  return isEditing ? (
    <Box
      ref={containerRef}
      className="ahhg-slider1"
      __css={{
        ...styles.container,
      }}
    >
      <Box __css={{ ...styles.divider, borderColor: color?.fields?.colorcode?.value ?? styles.divider.borderColor }} />
      <Box
        textStyle="h2"
        __css={{ ...styles.categoryText, color: color?.fields?.colorcode?.value ?? styles.categoryText.color }}
      >
        <Text tag="h2" field={category} />
      </Box>
      <Swiper
        className="sliderContainer"
        modules={[Scrollbar]}
        slidesPerView={'auto'}
        scrollbar={{ draggable: true, horizontalClass: 'swiper-scrollbar-slider1' }}
        grabCursor
        longSwipesRatio={0.1}
        breakpoints={{
          //IF in editing, dont use the reRender hook
          0: { slidesPerView: 6 },
          768: { slidesPerView: 6 },
        }}
      >
        {slider1Items &&
          slider1Items.map((card, index) => {
            return (
              <SwiperSlide key={`${card.fields.title.value} ${index}`} className="swiperSlide">
                <Slider1Item
                  fields={{ ...card.fields }}
                  params={props.params}
                  rendering={props.rendering}
                  key={`${card.fields.title.value} ${index}`}
                  iconContainerBackground={iconContainerBackgroundColor}
                  iconColor={iconColor}
                />
              </SwiperSlide>
            );
          })}
        {showMoreLink.value.href && showMoreLink.value.text ? (
          <SwiperSlide>
            <Link href={showMoreLink.value.href}>
              <Box sx={styles.showMoreContainer}>
                <Box
                  __css={styles.showMoreIcon}
                  color={color?.fields?.colorcode?.value ?? 'black'}
                  bg={transparentColor}
                >
                  <Plus size={38} strokeWidth={1.5} />
                </Box>
                <Box textStyle="p" __css={styles.showMoreText} color={color?.fields?.colorcode?.value ?? 'black'}>
                  {showMoreLink.value.text}
                </Box>
              </Box>
            </Link>
          </SwiperSlide>
        ) : null}
      </Swiper>
    </Box>
  ) : (
    <Box
      ref={containerRef}
      className="ahhg-slider1"
      __css={{
        ...styles.container,
      }}
    >
      <Box __css={{ ...styles.divider, borderColor: color?.fields?.colorcode?.value ?? styles.divider.borderColor }} />
      <Box
        textStyle="h2"
        __css={{ ...styles.categoryText, color: color?.fields?.colorcode?.value ?? styles.categoryText.color }}
      >
        <Text tag="h2" field={category} />
      </Box>
      {dimensions && hasScrolledInto ? (
        <div style={{ position: 'relative' }}>
          <Box
            __css={styles.arrowNext}
            className="swiper-button image-slider1-button-next"
            backgroundColor={color?.fields?.colorcode?.value ?? 'white'}
          >
            <svg
              width="31"
              height="21"
              viewBox="0 0 31 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke={hostelContainerBgColor}
            >
              <line x1="28" y1="10.4178" x2="6.11959e-08" y2="10.4178" stroke="inherit" strokeWidth="1.4" />
              <path d="M21 20.418C21 14.8951 25.4772 10.418 31 10.418" stroke="inherit" strokeWidth="1.4" />
              <path d="M31 10.418C25.4772 10.418 21 5.94082 21 0.417969" stroke="inherit" strokeWidth="1.4" />
            </svg>
          </Box>
          <Box
            __css={styles.arrowPrev}
            className="swiper-button image-slider1-button-prev"
            backgroundColor={color?.fields?.colorcode?.value ?? 'white'}
          >
            <svg
              width="31"
              height="21"
              viewBox="0 0 31 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke={hostelContainerBgColor}
            >
              <line x1="3" y1="10.4182" x2="31" y2="10.4182" stroke="inherit" strokeWidth="1.4" />
              <path d="M10 0.417966C10 5.94082 5.52285 10.418 0 10.418" stroke="inherit" strokeWidth="1.4" />
              <path d="M-2.38419e-06 10.418C5.52285 10.418 10 14.8951 10 20.418" stroke="inherit" strokeWidth="1.4" />
            </svg>
          </Box>
          <Swiper
            className="sliderContainer"
            modules={[Scrollbar, Navigation]}
            scrollbar={{ draggable: true, horizontalClass: 'swiper-scrollbar-slider1' }}
            grabCursor
            longSwipesRatio={0.1}
            navigation={{
              nextEl: '.image-slider1-button-next',
              prevEl: '.image-slider1-button-prev',
              disabledClass: 'slider1-button-disabled',
            }}
            breakpoints={{
              // 0: { slidesPerView: dimensions.contentBox.width / (140 + 10) },
              0: { slidesPerView: 'auto' },
              768: { slidesPerView: 'auto' },
            }}
          >
            {slider1Items &&
              slider1Items.map((card, index) => (
                <SwiperSlide key={`${card.fields.title.value} ${index}`}>
                  <Slider1Item
                    fields={{ ...card.fields }}
                    params={props.params}
                    rendering={props.rendering}
                    iconContainerBackground={iconContainerBackgroundColor}
                    iconColor={iconColor}
                    key={`${card.fields.title.value} ${index}`}
                  />
                </SwiperSlide>
              ))}
            {showMoreLink.value.href && showMoreLink.value.text ? (
              <SwiperSlide>
                <Link href={showMoreLink.value.href}>
                  <Box sx={styles.showMoreContainer}>
                    <Box
                      __css={styles.showMoreIcon}
                      color={color?.fields?.colorcode?.value ?? 'black'}
                      bg={transparentColor}
                    >
                      <Plus size={38} strokeWidth={1.5} />
                    </Box>
                    <Box textStyle="p" __css={styles.showMoreText} color={color?.fields?.colorcode?.value ?? 'black'}>
                      {showMoreLink.value.text}
                    </Box>
                  </Box>
                </Link>
              </SwiperSlide>
            ) : null}
          </Swiper>
        </div>
      ) : null}
    </Box>
  );
};

export default withDatasourceCheck()(Slider1);
