import {
  Box,
  FormControl,
  Input,
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useBookingMenuStore, useMenuStore, useChangeBookingStore } from '../../../store';
import CButton from 'components/buttons/CButton';
import { useEffect, useRef, useState } from 'react';
import { useI18n } from 'next-localization';
import { fetchChangeReservation } from 'lib/booking-helpers';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { isBefore } from 'date-fns';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  buttonText?: string;
}

const ChangeReservationModal = (props: Props): JSX.Element => {
  const { t, locale } = useI18n();
  const { sitecoreContext } = useSitecoreContext();

  const { isOpen, onClose, title, message, buttonText = t('booking_alert_close_text') } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { setModalOpen, setChangeStepOpen } = useBookingMenuStore((state) => state);
  const { setChangeReservationDetails } = useChangeBookingStore((state) => state);
  const { setMenuIsOpen } = useMenuStore();

  const openChangeAndModalHandler = () => {
    setMenuIsOpen(false);
    setModalOpen(true);
    setChangeStepOpen(true);
  };

  const styles = useMultiStyleConfig('Modal', { form: { width: '50%' } });

  useEffect(() => {
    if (isOpen) {
      setError('');
    }
  }, [isOpen]);

  const reservationNumberRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);

  const fetchReservationDetails = async (reservationNumber: number, lastName: string) => {
    if (lastName && reservationNumber) {
      const reservationDetails = await fetchChangeReservation(
        reservationNumber,
        lastName,
        sitecoreContext.site?.name,
        locale()
      );
      return reservationDetails;
    }
    return undefined;
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      reservationNumberRef.current &&
      reservationNumberRef.current.value &&
      lastNameRef.current &&
      lastNameRef.current.value
    ) {
      try {
        setLoading(true);
        const fetchedReservation = await fetchReservationDetails(
          parseInt(reservationNumberRef.current.value),
          lastNameRef.current.value
        );
        console.log('fetchedReservation.data.cancelDeadline', fetchedReservation!.data.cancelDeadline);
        console.log('new Date()', new Date());
        console.log('isBefore', isBefore(new Date(fetchedReservation!.data.cancelDeadline), new Date()));
        if (fetchedReservation && isBefore(new Date(fetchedReservation.data.cancelDeadline), new Date())) {
          throw new Error(t('booking_change_error'));
        }
        setChangeReservationDetails(fetchedReservation!.data);
        onClose();
        openChangeAndModalHandler();
      } catch (error) {
        setError(t('booking_change_error'));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent sx={styles.container}>
        <ModalCloseButton __css={styles.closeButton} />
        <Box __css={styles.title}>
          <Text>{title}</Text>
        </Box>
        <Box __css={styles.divider} />
        <Box __css={styles.message}>
          <Text whiteSpace="pre-wrap">{message}</Text>
          <Text whiteSpace="pre-wrap" color={'red'}>
            {error}
          </Text>
        </Box>
        <Box __css={styles.formContainer}>
          <form action="" onSubmit={submitHandler}>
            <FormControl>
              <Input
                required
                type={'text'}
                ref={reservationNumberRef}
                placeholder={t('booking_resnumber_placeholder')}
              />
            </FormControl>
            <FormControl mt={'1rem'}>
              <Input required type={'text'} ref={lastNameRef} placeholder={t('booking_surname_placeholder')} />
            </FormControl>
            <CButton
              type="submit"
              text={buttonText}
              backgroundColor={styles.button.backgroundColor as string}
              color={styles.button.color as string}
              marginTop={'1rem'}
              loading={loading}
            />
          </form>
        </Box>
      </ModalContent>
    </ChakraModal>
  );
};

export default ChangeReservationModal;
